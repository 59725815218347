#footer {
  background: linear-gradient(to left, rgb(0, 194, 203) 0%,
          rgb(84, 59, 159) 100%) top;

  width: 100vw;
  padding: 10px 30px;

  color: white;
}

#footer p {
  font-weight: 100;
  font-size: 10px;
  color: white;
  text-align: center;
}