#contact {
  background: linear-gradient(to bottom, rgba(106, 228, 232, 0.71) 0%,
          rgb(0, 194, 203) 100%) top;

  width: 100vw;
  color: #543B9FFF;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#contact h1 {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 10px;
}

#contact .content {
  text-align: justify;
  padding: 20px 30px;
}

#contact .content p {
  margin: 6px;
}

#contact .container {
  border-radius: 3px;
  background: white;
  width: 70%;
}

#contact .form-group {
  background: white;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #543B9FFF;
}

#contact .form-group button {
  margin: 8px;
}


@media (max-width: $laptop) {
  #contact {
    padding: 25px;
  }
}

@media (max-width: $tablet) {
  #contact {
    padding: 20px;
    align-items: center;
  }

  #contact h1 {
    font-weight: 300;
    font-size: 25px;
    margin-bottom: 10px;
  }

  #contact .container {
    border-radius: 3px;
    background: white;
    width: 100%;
  }
}

@media (max-width: $small-mobile) {
  #contact {
    padding: 10px;
    align-items: center;
  }

  #contact h1 {
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
  }

  #contact .container {
    border-radius: 3px;
    background: white;
    width: 100%;
  }
}