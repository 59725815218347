#header-overview {
  background: linear-gradient(to right, rgb(0, 194, 203) 0%,
          rgb(84, 59, 159) 100%) top;

  width: 100vw;
  padding: 0 30px;

  color: white;
}

#header-overview h1 {
  font-weight: 800;
  font-size: 50px;
}

#header-overview .tagline {
  font-weight: 100;
  font-size: 20px;
  font-style: italic;
  padding: 10px 0;
}

@media (max-width: $laptop) {
  #header-overview {
    padding: 25px;
  }

  #header-overview h1 {
    font-weight: 700;
    font-size: 40px;
  }
}

@media (max-width: $tablet) {
  #header-overview {
    padding: 20px;
  }

  #header-overview h1 {
    font-weight: 600;
    font-size: 30px;
  }
}

@media (max-width: $small-mobile) {
  #header-overview {
    padding: 15px;
  }

  #header-overview h1 {
    font-weight: 500;
    font-size: 20px;
  }

  #header-overview .tagline {
    font-weight: 100;
    font-size: 10px;
    font-style: italic;
    padding: 5px 0;
  }
}