#work {
  width: 100vw;
  border-top: 1px solid #543B9FFF;
  border-bottom: 1px solid #543B9FFF;
  background-color: rgba(199, 248, 248, 0.97);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#work h1 {
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

#work .content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

#work .content p {
  margin: 6px;
}

#work img {
  height: 150px;
  margin: 20px 20px 0 20px;
  border: none;
  border-radius: 5px;
}

#work figcaption {
  font-weight: 100;
  font-size: 10px;
  text-align: center;
  margin-bottom: 20px;
}


@media (max-width: $laptop) {
  #work {
    padding: 25px;
  }

  #work img {
    height: 125px;
    margin: 12px 12px 0 12px;
  }

  #work figcaption {
    font-size: 9px;
    margin-bottom: 15px;
  }
}

@media (max-width: $tablet) {
  #work {
    padding: 20px;
  }

  #work .content {
    flex-direction: column;
    padding: 15px;
    align-items: center;
  }

  #work img {
    height: 105px;
    margin: 10px 10px 0 10px;
  }
}

@media (max-width: $small-mobile) {
  #work {
    padding: 15px;
  }

  #work .content {
    flex-direction: column;
    padding: 15px;
    align-items: center;
  }

  #work img {
    height: 75px;
    margin: 10px 10px 0 10px;
  }
}