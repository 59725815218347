@font-face {
  font-family: 'Oswald', sans-serif;
  src: local('Cookie'), url(../fonts/Oswald-VariableFont_wght.ttf) format('truetype');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Oswald', sans-serif;
}


