#about {
  background: linear-gradient(to bottom, rgba(106, 228, 232, 0.71) 0%,
          rgb(0, 194, 203) 100%) top;

  width: 100vw;
  color: #543B9FFF;
  display: flex;
  align-items: center;
}

#about h1 {
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  margin-left: 6px;
  margin-bottom: 10px;
}

#about .content {
  text-align: justify;
  padding: 20px 30px;
}

#about .content p {
  margin: 6px;
}

#about img {
  height: 250px;
  margin: 20px 20px 0 20px;
}

#about figcaption {
  color: white;
  font-weight: 100;
  font-size: 10px;
  text-align: center;
  margin-bottom: 20px;
}


@media (max-width: $desktop) {
  #about img {
    height: 225px;
    margin: 15px 15px 0 15px;
  }

  #about figcaption {
    margin-bottom: 18px;
  }
}

@media (max-width: $laptop) {
  #about {
    padding: 25px;
  }

  #about img {
    height: 200px;
    margin: 12px 12px 0 12px;
  }

  #about figcaption {
    font-size: 9px;
    margin-bottom: 15px;
  }
}

@media (max-width: $tablet) {
  #about {
    padding: 20px;
  }

  #about img {
    display: none;
  }

  #about figcaption {
    display: none;
  }
}

@media (max-width: $small-mobile) {
  #about {
    padding: 15px;
  }

  #about img {
    display: none;
  }

  #about figcaption {
    display: none;
  }
}